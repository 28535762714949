<template>
  <div>
    <pageBread
      :img="() => require('../../assets/image/zhuye.png')"
      text="提交打包"
    ></pageBread>
    <div class="step">
      <p class="step-title">第一步：确认包裹</p>
      <div class="step-container" style="color: #999">
        <p style="word-wrap: break-word">
          您提交的包裹为：{{ packageInfo.stockNameList }}
        </p>
        <p>共计重量为：{{ packageInfo.allWeight }}KG</p>
      </div>
    </div>
    <div class="step">
      <p class="step-title">第二步：确认收货地址</p>
      <div class="page-bread" @click="$router.push('/address-add')">
        添加地址
      </div>
      <div class="step-container">
        <div v-for="item in addressList" :key="item.id" class="address-item">
          <input
            v-model="params.addressId"
            type="radio"
            name="address"
            :value="item.id"
          />
          <span>
            {{ item.nationName }}&nbsp;&nbsp;{{ item.recipient }}&nbsp;&nbsp;{{
              item.post
            }}&nbsp;&nbsp;{{ item.detailAddress }}&nbsp;&nbsp;{{
              item.tel
            }}</span
          >
        </div>
      </div>
    </div>
    <div class="step">
      <p class="step-title">第三步：请提交申报详情与打包方式</p>
      <p style="font-size: 12px">每行填写一个货物，中文或英文</p>
      <div class="step-container">
        <table class="shop-table">
          <thead>
            <th>品名</th>
            <th>数量</th>
            <th>单价</th>
            <th>总价</th>
          </thead>
          <tbody>
            <template v-for="(item, index) in shopList">
              <tr :key="index">
                <td>
                  <input
                    type="text"
                    style="width: 17vw; height: 25px"
                    v-model.trim="item.detailNum"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    style="width: 17vw; height: 25px"
                    v-model="item.quantity"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    style="width: 17vw; height: 25px"
                    v-model="item.price"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    style="width: 17vw; height: 25px"
                    :value="Number(item.quantity) * Number(item.price) || 0"
                    disabled
                  />
                </td>
                <td>
                  <span
                    style="
                      background: #ededed;
                      padding: 3px;
                      border-radius: 3px;
                    "
                    @click="deleteItem(index)"
                    >删除</span
                  >
                </td>
              </tr>
              <tr :key="index + 'err'">
                <td style="color: red; width: 70px">
                  <div style="">{{ item.error }}</div>
                </td>
                <td style="color: red; width: 70px">
                  <div style="">{{ item.error2 }}</div>
                </td>
                <td style="color: red; width: 70px">
                  <div style="">{{ item.error3 }}</div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <div style="text-align: right; padding-top: 10px">
          <button
            class="nq-button"
            style="padding: 5px 8px"
            @click="addNewShop"
          >
            添加一行
          </button>
        </div>
      </div>
    </div>
    <div class="step">
      <p class="step-title"><span style="color: red">*</span>申报方式：</p>
      <ul>
        <li
          v-for="item in baseDict.declaretType"
          :key="item.dictName"
          class="way-item"
        >
          <input
            type="radio"
            :value="item.id"
            name="shenbao"
            v-model="params.declareCode"
          />
          {{ item.dictName }}
        </li>
      </ul>
      <p class="step-title"><span style="color: red">*</span>打包方式：</p>
      <ul>
        <li
          v-for="item in baseDict.packWayType"
          :key="item.dictName"
          class="way-item"
        >
          <input
            type="radio"
            :value="item.id"
            name="dabao"
            v-model="params.packMethodCode"
          />
          {{ item.dictName }}
        </li>
      </ul>
      <p class="step-title"><span style="color: red">*</span>是否含有电池：</p>
      <ul>
        <li
          v-for="item in baseDict.battery"
          :key="item.dictName"
          class="way-item"
        >
          <input
            type="radio"
            :value="item.id"
            name="dianchi"
            v-model="params.isBattery"
          />
          {{ item.dictName }}
        </li>
      </ul>
      <p class="step-title"><span style="color: red">*</span>是否有液体：</p>
      <ul>
        <li class="way-item">
          <input
            type="radio"
            :value="1"
            name="yeti"
            v-model="params.isLiquid"
          />
          含有
        </li>
        <li class="way-item">
          <input
            type="radio"
            :value="2"
            name="yeti"
            v-model="params.isLiquid"
          />
          不含有
        </li>
      </ul>
      <p class="step-title">
        <span style="color: red">*</span>是否含有一线大品牌：
      </p>
      <ul>
        <li class="way-item">
          <input type="radio" :value="1" name="yxpp" v-model="params.isBrand" />
          含有
        </li>
        <li class="way-item">
          <input type="radio" :value="2" name="yxpp" v-model="params.isBrand" />
          不含有
        </li>
      </ul>
      <p class="step-title">其他备注：</p>
      <textarea
        v-model="params.buyNote"
        rows="3"
        :maxlength="200"
        style="width: 90%"
      ></textarea>
      <div style="text-align: center; margin: 10px 0px">
        <button class="nq-button" @click="submitToPackage" v-loading="packging">
          提交打包
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { addressInfo } from '../../utils/apis/apis'
import { orderInfo } from '../../utils/coupon'
export default {
  name: 'submitPackage',
  data() {
    return {
      packging: false,
      shopList: [
        {
          detailNum: '',
          quantity: '',
          price: '',
          totalPrice: ''
        }
      ],
      packageInfo: {},
      addressList: [],
      baseDict: {},
      pageInfo: {
        pageNum: 1,
        pageSize: 200
      },
      params: {
        addressId: '', // 地址簿id
        declareCode: '12201', // 申报方式编码
        packMethodCode: '1301', // 打包方式编码
        isBattery: '1402', // 是否含有电池
        isLiquid: '2', // 是否含有液体
        isBrand: '2', // 是否含有一线大牌
        oddNumbers: [], // 物流单号列表
        detailMemberReqs: [], // 订单申报详情
        buyNote: ''
      },
      activeId: ''
    }
  },
  created() {
    if (sessionStorage.orderPackageInfo) {
      this.packageInfo = JSON.parse(sessionStorage.orderPackageInfo)
    }
    this.getAddress()
  },
  methods: {
    getAddress() {
      // addressInfo
      this.$reqPost(addressInfo.list, this.pageInfo).then((res) => {
        if (res.data.code === 200) {
          this.addressList = [...this.addressList, ...res.data.data.list]
          this.params.addressId = res.data.data.list[0].id
        }
      })
      this.$reqGet(orderInfo.getBaseDict).then((res) => {
        if (res.data.code === 200) {
          this.baseDict = res.data.data
        }
      })
    },
    addNewShop() {
      this.shopList.push({
        detailNum: '',
        quantity: '',
        price: '',
        totalPrice: ''
      })
    },
    deleteItem(index) {
      this.shopList.splice(index, 1)
    },
    submitToPackage() {
      let errNum = 0
      let errNum2 = 0
      let errNum3 = 0
      this.shopList.forEach((item) => {
        item.error = ''
        item.error2 = ''
        item.error3 = ''
        if (item.detailNum === '') {
          item.error = '对不起，该项不能为空！'
          errNum++
        }
        if (item.quantity === '') {
          item.error2 = '对不起，该项不能为空！'
          errNum2++
        }
        if (item.price === '') {
          item.error3 = '对不起，该项不能为空！'
          errNum3++
        }
        this.$forceUpdate()
      })
      // console.log(this.shopList)
      if (errNum > 0) {
        return
      }
      if (errNum2 > 0) {
        return
      }
      if (errNum3 > 0) {
        return
      }
      if(this.packging){
        return
      }
      if(this.packging ){
        return false
      }
      this.packging = true
      this.params.detailMemberReqs = this.shopList
      this.params.oddNumbers = this.packageInfo.stockNameList.split(',')
      this.$reqPost(orderInfo.addOrderInfo, this.params).then((res) => {
        if (res.data.code === 200) {
          this.$toast.success('打包成功')
          setTimeout(() => {
            this.$router.replace('/user-center')
          },100)
        }else{
          this.packging = false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
#sorry {
  display: none;
}
.step {
  padding: 0px 10px;
}
.step-container {
  background: #fff;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 10px;
}
.address-item {
  background: #ededed;
  padding: 5px;
  border: 1px solid #dddddd;
  border-radius: 3px;
}
input[type="radio"] {
  margin-right: 8px;
}
.step-title {
  padding: 8px 0px;
}
.shop-table {
  width: 100%;
  color: #999;
}
.page-bread {
  border-radius: 5px;
  background: #04b8ed;
  color: #fff;
  line-height: 45px;
  text-align: center;
  height: 45px;
  margin: 10px;
}
.way-item {
  margin-bottom: 8px;
  font-size: 15px;
}
/deep/ .step-container {
  word-break: break-all;
}
</style>
